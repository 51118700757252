.Uppercase {
  text-transform: uppercase;
}

.InnerContent, 
.ExtendedStep {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  color: var(--black);
}

.InnerContent ol {
  width: 100%;
}

.InnerContent li {
  width: 100%;
  overflow: auto;
}

.InnerContent b {
  word-break: break-word;
}