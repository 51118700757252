.Banner {
  background-color: var(--blue);
  padding: 16px;
  text-align: center;
}

.Banner > a {
  color: var(--black-2);
  text-decoration: underline;
}

.Banner > * {
  display: block;
}
